// Here you can add other styles
.test.alert.alert-success.fade.show {
    display: none;
}

canvas {
  height: 600px;
}

#reactgooglegraph-1 table {
    width: 100%;
}

#reactgooglegraph-1 td div {
    text-align: center;
    right: 0;
    margin: auto;
}

#reactgooglegraph-1 td:empty {
    display: none;
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1370px;
  }
  
  #reactgooglegraph-1 td{
  	width:16.6667% !important

  }
}
.compliancestandards table {
  width: 100%;
}

.compliancestandards td div {
  text-align: center;
  right: 0;
  margin: auto;
}

.compliancestandards td:empty {
  display: none;
}

@media screen and (min-width: 1400px) {

.compliancestandards td{
  width:16.6667% !important

}
}
@media screen and (min-width: 1600px) {
  .container {
    width: 1570px;
  }
  .card.text-white {
    font-size: 1.6rem;
  }
  .card.text-white .text-value-lg {
    font-size: 2rem;
  }

}
@media screen and (min-width: 1900px) {
  .container {
    width: 1870px;
  }
  canvas {
    height: 800px;
  }
  .risk-box-inner {
    font-size: 40px !important;
    margin-bottom: 40px !important;
  }
 .risk-box > div > div > div {
  font-size: 60px;
  margin-top: 30px;
  }
 
}
@media screen and (min-width: 1800px){
  .risk-box-inner {
    font-size: 30px !important;
    margin-bottom: 40px !important;
  }
  .risk-box > div > div > div {
  font-size: 60px;
  margin-top: 30px;

  }
}
@media screen and (min-width:1800px) and (max-width:2000px){
  .risk-box-inner {
    font-size: 25px !important;
    margin-bottom: 40px !important;
  }
  .risk-box > div > div > div {
    font-size: 40px;
    margin-top: 30px;
    }
}


@media screen and (max-width: 767px) {
  canvas {
      height: 300px;
  }
}
@media screen and (min-width:1700px) and (max-width:1799px){
  .risk-box-inner {
    font-size: 25px !important;
    margin-bottom: 40px !important;
  }
  .risk-box > div > div > div {
    font-size: 40px;
    margin-top: 15px;
  }
}

.col-sm-6.form-inline.p-0.c-datatable-filter {
  margin-bottom: 10px;
}

.col-sm-6 > .card.chart-card
{
  height:calc(100% - 1.4rem);
}
@media(max-width:375px){
  .pagination li:first-child,
  .pagination li:last-child
  {
    display:none    
  }
}



// .pagination {
//   margin-top: 10px;
// }